import httpUtil from "@/utils/httpUtil";
import config from "@/config"

/** 获取商户数据 */
export function center(params,type) {
    return httpUtil.get(`${config.API_URL}/api/user/center`, params, type)
}
/** 获取商户数据 */
export function indexInfo(params,type) {
    return httpUtil.get(`${config.API_URL}/api/index/index`, params, type)
}
/** 密码管理 */
export function changePassword(params,type) {
    return httpUtil.post(`${config.API_URL}/api/user/changePassword`, params, type)
}
/** 银行卡列表 */
export function bankLists(params,type) {
    return httpUtil.get(`${config.API_URL}/api/user_bank/lists`, params, type)
}
/** 添加银行卡 */
export function bankAdd(params,type) {
    return httpUtil.post(`${config.API_URL}/api/user_bank/add`, params, type)
}
/** 删除银行卡 */
export function bankDel(params,type) {
    return httpUtil.post(`${config.API_URL}/api/user_bank/del`, params, type)
}
/** 银行卡详情 */
export function bankDetail(params,type) {
    return httpUtil.post(`${config.API_URL}/api/user_bank/detail`, params, type)
}

/** 入金申请列表 */
export function dfrechargeList(params,type) {
    return httpUtil.get(`${config.API_URL}/api/dfrecharge_order/lists`, params, type)
}
/** 申请入金 */
export function dfrechargeAdd(params,type) {
    return httpUtil.post(`${config.API_URL}/api/dfrecharge_order/recharge`, params, type)
}

/** 获取代付余额 */
export function getBalance(params,type) {
    return httpUtil.get(`${config.API_URL}/api/user/getBalance`, params, type)
}
/** 提交手动代付 */
export function apply(params,type) {
    return httpUtil.post(`${config.API_URL}/api/df_order/apply`, params, type)
}

/** 交易统计列表 */
export function get_deal_static(params,type) {
    return httpUtil.get(`${config.API_URL}/api/index/get_deal_static`, params, type)
}

/** 获取账变类型 */
export function getdict(params,type) {
    return httpUtil.get(`${config.API_URL}/api/index/getdict`, params, type)
}
/** 商户账变列表 */
export function zbLists(params,type) {
    return httpUtil.get(`${config.API_URL}/api/account_log/lists`, params, type)
}

/** 代付订单 */
export function df_order(params,type) {
    return httpUtil.get(`${config.API_URL}/api/df_order/lists`, params, type)
}

/** 订单详情 */
export function df_orderDetails(params,type) {
    return httpUtil.get(`${config.API_URL}/api/df_order/detail`, params, type)
}
/** 代付费率 */
export function getDfRate(params,type) {
    return httpUtil.get(`${config.API_URL}/api/index/getDfRate`, params, type)
}
/** API信息 */
export function apiinfo(params,type) {
    return httpUtil.get(`${config.API_URL}/api/index/apiinfo`, params, type)
}
/** 查看密钥 */
export function getKey(params,type) {
    return httpUtil.post(`${config.API_URL}/api/index/getKey`, params, type)
}
/** 获取谷歌验证码 */
export function getGoogleInfo(params,type) {
    return httpUtil.post(`${config.API_URL}/api/user/getGoogleInfo`, params, type)
}
/** 绑定谷歌 */
export function bindGoogle(params,type) {
    return httpUtil.post(`${config.API_URL}/api/user/bindGoogle`, params, type)
}

/** 查看谷歌密钥 */
export function seeGoogleKey(params,type) {
    return httpUtil.post(`${config.API_URL}/api/user/seeGoogleKey`, params, type)
}


/** 获取我的下级商户列表 */
export function childerlist(params,type) {
    return httpUtil.get(`${config.API_URL}/api/user/childerlist`, params, type)
}
/** 添加下级商户 */
export function addChilderUser(params,type) {
    return httpUtil.post(`${config.API_URL}/api/user/addChilderUser`, params, type)
}

/** 交易记录 + 导出 */
export function dai_lists(params,type) {
    return httpUtil.get(`${config.API_URL}/api/df_order/dai_lists`, params, type)
}

/** 获取下级代付费率 */
export function getAgentDfrate(params,type) {
    return httpUtil.get(`${config.API_URL}/api/user/getAgentDfrate`, params, type)
}
/** 修改下级商户代付费率 */
export function setAgentDfRate(params,type) {
    return httpUtil.post(`${config.API_URL}/api/user/setAgentDfRate`, params, type)
}










