<template>
	<div class="view pa24" >
		<commonTable :tableData="tableData" :loading="loading">
			<template v-slot:table>
				<!-- <el-table-column prop="windowsName" align="center" label="名称"  /> -->
				<el-table-column prop="min_money" align="center" label="最低金额" />
				<el-table-column prop="max_money" align="center" label="最高金额" />
				<el-table-column prop="fee1" align="center" label="费率"/>
				<el-table-column prop="fee2" align="center" label="单笔费用"/>
			</template>
		</commonTable>
	</div>
</template>

<script>
	import commonTable from "@/components/common/commonTable";
	import {
		mapState
	} from 'vuex';
	import {
		getDfRate,
	} from "@/api/settlement";
	export default {
		name: 'windowSet',
		data() {
			return {
				tableData: [],
				loading: false, //表格加载
			};
		},
		components: {
			commonTable,
		},
		mounted() {
			this.queryPage();
		},

		methods: {
			async queryPage() {
				let data = {};
				try {
					this.loading = true;
					const result = await getDfRate(data);
					this.loading = false;
					this.tableData = [result.data];
				} catch (error) {
					this.tableData = []
					this.loading = false;
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
</style>