import axios from 'axios'
import router from "../router";


let headers = {
    'content-Type':'application/json; charset=utf-8',
}

// axios配置参数
//axios.defaults.headers = headers
axios.defaults.timeout = 1000 * 10

// 请求拦截器
axios.interceptors.request.use(config => {
        let token = localStorage.getItem("token")
        config.headers = headers
        if (token) {
            config.headers.token = token
        } else {
            router.replace({path: '/login'});
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

axios.interceptors.response.use(
    (response) => {
        if (response.data.code == -1) {
            router.replace({path: '/login'});
        }
        return response;
    },
    (error) => {
        return Promise.reject(error.response);
    }
);



/**
 * http请求
 */

export default class httpUtil {
    /**
     * get请求
     * @param url
     * @param params
     * @returns {Promise<R>}
     */

    static get(url, params) {
        return new Promise( (resolve, reject) => {
            axios({
                method: 'get',
                url: url,
                params: params,
            }).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            })
        });
    }

    /**
     * POST请求
     * @param url
     * @param params
     * @returns {Promise<R>}
     */
    static post(url, params, type) {
        return new Promise( (resolve, reject) => {
            axios({
                method: 'post',
                url: url,
                data: params,
            }).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            })
        });
    }
}
